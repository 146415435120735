﻿/// <reference path="../../../main">

.overview {
    & + * {
        @include responsive-context($heading-spacing-map) {
            margin-top: $responsive-variable;
        }
    }
}

.full {
    .overview, .outro {
        @extend %max-total-width;
        @extend %standard-padding-h;
    }
}

.outro {
    @at-root {
        * + & {
            @include responsive-context($heading-spacing-map) {
                margin-top: $responsive-variable;
            }
        }
    }
}