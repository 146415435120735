﻿/// <reference path="../../../main">

.alert {
    border-radius: 0;
    padding: $gap-xs 0;
    margin-bottom: 0;
    border: 0;

    &-dismissible .close {
        color: $true-black;
        right: 0;
    }

    &-inner {
        @include responsive-context($standard-padding-map) {
            padding-left: $responsive-variable;
            padding-right: $responsive-variable;
        }
    }
}

$alert-themes: (
    "info": (#31708f, #d9edf7),
    "success": (#3c763d, #dff0d8),
    "warning": (#8a6d3b, #fcf8e3),
    "danger": ($c1)
    );

@each $_variant, $_color in $alert-themes {
    $_bg: rgba(nth($_color, 1), 0.15);
    @if length($_color) == 2 {
        $_bg: nth($_color, 2);
    }
    .alert-#{$_variant} {
        background-color: $_bg;
        color: nth($_color, 1);

        a:not([class*="btn"]) {
            @include link-helper(nth($_color, 1), $true-black);
        }

        .close {
            color: nth($_color, 1);
        }
    }
}