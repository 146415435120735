﻿/// <reference path="../../../main">

$_tab-padding: $space;
$_tab-panel-padding: $gap-sm;
$_tab-underline-color: $c4;
$_tab-selected-underline-color: $c1;
$_tab-selected-underline-width: 5px;

.tabs {
    &-list {
        display: flex;
        border-bottom: 1px solid $_tab-underline-color;
        flex-wrap: wrap;
        max-width: $max-line-length;

        &-item {
            flex: 0 1 auto;
            margin-bottom: -1px;
            margin-right: -1px;
            overflow: hidden;
            border: 1px solid $_tab-underline-color;
        }

        &-tab {
            @extend %un-button;
            padding: $_tab-padding;
            border-bottom: $_tab-selected-underline-width solid transparent;
            position: relative;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;

            &:hover,
            &[aria-selected="true"] {
                border-bottom: $_tab-selected-underline-width solid $_tab-selected-underline-color;
            }

            &:focus {
                outline-offset: -2px;
                outline-color: rgba($c2, 0.5);
                outline-style: dotted;
                outline-width: 1px;
            }
        }
    }

    &-panel {
        padding: $_tab-panel-padding 0;
    }
}

// PAGINATION
.tabs {
    &-pagination {
        margin-top: $_tab-panel-padding;
        display: flex;

        &-btn {
            @extend %un-button;
        }

        &-next,
        &-previous {
            flex: 1 0 auto;
        }

        &-next {
            text-align: right;

            &::before {
                content: 'Next: ';
            }

            &::after {
                content: '\00a0\2192';
            }
        }

        &-previous {
            text-align: left;

            &::before {
                content: '\2190 Previous: ';
            }
        }
    }
}