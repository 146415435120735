﻿/// <reference path="../../../main">

.fifty-fifty {
    &-inner {
        @include media-min(small) {
            display: flex;
            justify-content: center;

            & > * {
                width: 50%;
                flex: 0 0 auto;
            }
        }
    }

    &-reversed {
        flex-direction: row-reverse;
    }

    &-panel {
        &-txt {
            display: flex;
            align-items: center;

            @include responsive-context($standard-padding-map, $vertical-padding-map, 'true') {
                padding: $responsive-variable-2 $responsive-variable;
            }

            @include media(small) {
                padding-top: $gap-sm;
            }

            & > * {
                width: ($max-total-width / 2) - ($max-standard-pad / 1rem * 16px * 2);
                max-width: 100%;
            }

            &.text-image-left {
                justify-content: flex-end;
            }
        }

        &-img {
            display: flex;
            align-items: center;

            & > button {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-image {
        display: block;
        position: relative;

        @supports(object-fit: cover) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
