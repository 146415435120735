﻿/// <reference path="../../../main">

.decorated-heading {
    @include media-range(small, large) {
        padding-top: $gap-xs;
    }

    @include responsive-context($heading-spacing-map) {
        &:nth-child(n) {
            padding-bottom: $responsive-variable;
        }
    }

    & + .block {
        @include responsive-context($heading-spacing-map) {
            margin-top: $responsive-variable;
        }
    }
}
