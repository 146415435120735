﻿/// <reference path="../../../main">

.svg-icon {
    width: $svg-icon-size;
    height: $svg-icon-size;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &-text {
        width: 1em;
        height: 1em;
        vertical-align: bottom;
    }
}

.block,
%block {
    max-width: $max-total-width;
    margin-left: auto;
    margin-right: auto;
}

.block {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    @include responsive-context($vertical-padding-map) {
        margin-top: $responsive-variable;

        &:last-child {
            margin-bottom: $responsive-variable;
        }
    }
}

%standard-padding-v {
    @include responsive-context($vertical-padding-map) {
        padding-top: $responsive-variable;
        padding-bottom: $responsive-variable;
    }
}

.block-filled {
    &:not(.c5-bg) {
        @extend %standard-padding-v;
    }
}

// exclude white background blocks
.full {
    max-width: none;

    &:not(.c5-bg) {
        & + & {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}

.block-inner,
%standard-padding-h {
    @include responsive-context($standard-padding-map) {
        padding-left: $responsive-variable;
        padding-right: $responsive-variable;
    }

    @at-root {
        .standard-content & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.max-total-width,
%max-total-width {
    max-width: $max-total-width;
    margin-left: auto;
    margin-right: auto;
}

.max-content-width,
%max-content-width {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
}

.block-fleuron {
    fill: currentColor;
    width: 12.5rem;
    height: 2.5rem;
    margin-top: $gap-xs;
}

%background-pattern,
.background-pattern {
    background-repeat: no-repeat, repeat;
    background-size: cover, 196px 196px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .15;

    @each $i, $c in $usn-colors {
        &.c#{$i}-bg-pattern {
            background-image: linear-gradient(to bottom, rgba($c, 1), rgba($c, 0)), url('/static/img/deco-white.png');
        }
    }
}

// text max widths
.text {
    & > * {
        max-width: $max-line-length;
    }

    & > .umbraco-forms-form {
        max-width: none;
    }

    @at-root {
        .text-center &, &.text-center, & [style*="align: center"] {
            &, & > * {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .text-right &, &.text-right, & [style*="align: right"] {
            &, & > * {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}

.heading {
    max-width: $max-line-length * 0.66;

    @at-root {
        .text-center &, &.text-center {
            margin-left: auto;
            margin-right: auto;
        }

        .text-right &, &.text-right {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &-with-preheader {
        display: flex;
        flex-direction: column;
    }
}

.preheader {
    order: -1;
    margin-top: 0;
}

.center-column {
    margin-left: auto;
    margin-right: auto;

    @include column-width(8);

    @include media(large) {
        @include column-width(12);
    }
}

// Pattern for a simple 2 column layout that has responsive margins and collapses to 1 column at the medium breakpoint
%simple-2-col-container,
.simple-2-col-container {
    @include responsive-context($heading-spacing-map) {
        margin-bottom: -$responsive-variable;
    }

    @include media-min(medium) {
        display: flex;
        flex-wrap: wrap;
    }
}

%simple-2-col-item,
.simple-2-col-item {
    @include responsive-context($gutter-map, $heading-spacing-map) {
        margin-right: $responsive-variable;
        margin-bottom: $responsive-variable-2;
    }

    @include media-min(medium) {
        flex: 0 0 auto;

        &:nth-child(even) {
            margin-right: 0;
        }
    }

    @include media-range(medium, large) {
        width: calc(50% - #{map-get($gutter-map, "large") / 2});
    }

    @include media-min(large) {
        @each $breakpoint, $value in $gutter-map { //Sass gets confused if you try to get the "null" item in a map so we have to iterate
            @if $breakpoint == null {
                width: calc(50% - #{$value / 2});
            }
        }
    }

    @include media(medium) {
        width: 100%;
        margin-right: 0;
    }
}

%full-cover {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}