﻿/// <reference path="../../../main">

.pod {
    &-container {
        @at-root {
            * + & {
                @include responsive-context($heading-spacing-map) {
                    margin-top: $responsive-variable;
                }
            }
        }

        @include media-min(medium) {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: -$max-column-gap;
        }

        &.items-1 {
            & > * {
                @include media-min(medium) {
                    @include column-width(8);
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &.items-2,
        &.items-4 {
            & > * {
                @include media-min(medium) {
                    width: calc(50% - #{$max-column-gap / 2});

                    &:nth-child(odd) {
                        margin-right: $max-column-gap;
                    }
                }
            }
        }

        &.items-3 {
            & > * {
                @include media-min(medium) {
                    margin-right: $max-column-gap;
                }

                @include media-min(large) {
                    width: calc(#{$one-third} - #{$max-column-gap * 2 / 3});

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }

                @include media-range(medium, large) {
                    width: calc(50% - #{$max-column-gap / 2});

                    &:nth-child(even) {
                        margin-right: 0;
                    }
                }
            }
        }

        &.items-4 {
            & > * {
                @include media-min(large) {
                    width: calc(25% - #{$max-column-gap * 3 / 4});
                    margin-right: $max-column-gap;

                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                }
            }
        }

        & > * {
            flex: 0 0 auto;
            max-width: 100%;

            @include media-min(medium) {
                margin-bottom: $max-column-gap;
            }

            @include media-range(small, medium) {
                margin-top: $max-column-gap;

                &:first-child {
                    margin-top: 0;
                }
            }

            @include media(small) {
                margin-top: $gap-xs;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &-item {
        display: flex;

        @at-root {
            .c5-bg-parent {
                border: 1px solid $c4;
            }
        }

        &-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;

            & > * {
                max-width: 100%;
            }
        }

        &-content {
            padding: $gap-xs;

            & > * {
                max-width: $max-line-length;
            }
        }

        &-cta {
            padding: $gap-xs;
            padding-top: 0;
            margin-top: auto;

            .link {
                margin-top: 0;
            }
        }

        &-image {
            order: -1;
            min-height: 1px;
        }
    }

    &-pattern {
        @extend %background-pattern;
    }

    &-listing {
        display: block;

        & > * {
            border: 0;

            &:first-child ~ * {
                margin-top: $gap;
            }
        }

        &-item-inner {
            flex-direction: row;

            .pod-item-content {
                flex: 1 1 auto;
                padding: 0;
            }

            .pod-item-image {
                flex: 0 0 auto;
            }

            @include responsive-context($gutter-map, null, true) {
                .pod-item-image {
                    margin-right: $responsive-variable;

                    @if $responsive-breakpoint == null or map-get($breakpoints, $responsive-breakpoint) >= $v-large-media-query-size {
                        width: calc(25% - #{$responsive-variable / 2});
                    }
                    @else {
                        width: calc(#{$one-third} - #{$responsive-variable / 2});
                    }
                }
            }

            @include media(small) {
                flex-direction: column;

                .pod-item-image {
                    width: 100%;
                    margin-right: 0;
                }

                .pod-item-content {
                    margin-top: $gap-xs;
                }
            }
        }
    }
}

// making one slide at a time narrower
.slides.items-1 {
    .item > * {
        @include media-min(medium) {
            @include column-width(8);
            margin-left: auto;
            margin-right: auto;
        }
    }
}