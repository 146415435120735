﻿/// <reference path="../../../main">
$_video-background: $c2;

.video-embed {
    position: relative;
    padding-bottom: 9/16 * 100%;
    width: 100%;
    max-width: 100%;
    background: $_video-background;

    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
