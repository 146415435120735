/// <reference path="../../../main">

$_button_padding-v: $space;
$_button-padding-h: $gap-xs;
$_button-primary-color: $c1;
$_button-secondary-color: $base-text;
$_button-svg-size: 1rem;

%btn,
%btn-tnew {
    @extend %small;
    font-family: $body-font;
    padding: $_button_padding-v $_button-padding-h;
    border: 1px solid $_button-primary-color;
    font-weight: $body-font-weight-bold;
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 0;

    &[disabled] {
        cursor: not-allowed;
        opacity: .4;
    }

    svg {
        width: $_button-svg-size;
        height: $_button-svg-size;
        vertical-align: middle;
    }

    &:hover {
        // override bootstrap trash
        @extend %pre-focus-styles;
    }

    @include focus();
}

%btn {
    display: inline-block;
}

span {
    &.btn,
    &.btn-sm,
    &.btn-xs,
    &.btn-lg {
        & > a {
            @extend %btn;
        }
    }
}

.btn-xs {
    padding: $_button_padding-v / 2 $_button-padding-h / 2;
}

.btn-lg {
    padding: $_button_padding-v * 1.5 $_button-padding-h * 1.5;
}

// More button helpers
%un-button,
.un-button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline;
    color: $base-text;
    text-align: left;

    &:hover,
    &:focus {
        background: transparent;
        color: $base-text;
        border: 0;
    }

    &[disabled],
    &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

%icon-btn,
.icon-btn {
    position: relative;
    line-height: 1;
    width: $svg-icon-size;
    height: $svg-icon-size;
    display: inline-block;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
}

.icon-btn-round {
    @extend %icon-btn;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.btn {
    &-play, &-pause {
        border: 1px solid $true-white;
        background: transparent;
        position: relative;

        &::before, &::after {
            border-color: $true-white;
            border-style: solid;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            height: $ui-icon-size;
            z-index: 1;
        }

        span {
            color: $true-white;
            position: absolute;
            right: calc(100% + #{$gap-xs});
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-play {
        &::before {
            content: '';
            height: 0;
            border-width: ($ui-icon-size / 2) $ui-icon-size;
            border-color: transparent;
            border-left-color: $true-white;
            transform: translate(-25%, -50%);
        }
    }

    &-pause {
        &::before, &::after {
            content: '';
            border-width: 0;
            border-left-width: 3px;
        }

        &::before {
            left: 38%;
        }

        &::after {
            left: auto;
            right: 38%;
        }
    }
}

.image-btn {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;

    img {
        display: block;
    }

    &:focus, &:hover {
        opacity: 0.8;
    }
}

.btn-play {
    &-large {
        &::before {
            transform: translate(10%, -50%) scale(2);
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(2);
            height: 2rem;
            width: 2rem;
            border: 1px solid $true-white;
            border-radius: 50%;
        }
    }
}
