﻿// USKINNED OVERRIDES

/* THEME PALETTE */

$red: #CF3339;
$med-gray: #4D4646;
$light-gray: #D9D9D9;

$c1: $red;
$c2: #000000;
$c3: #000000;
$c4: $light-gray;
$c5: #ffffff;
$c6: $light-gray;

$disabled-gray: $med-gray;

/* BASE PALETTE */

$true-black: #000000;
$true-white: #ffffff;
$base-heading: #181818;
$base-text: #000000;
$base-borders: #d9d9d9;
$form-errors: #cf3339;

/* 	
--------------------------------------------------
2.0 SIZES
-------------------------------------------------- 
*/

/* BASE */

$base-spacing: 2rem;
$base-half-spacing: 1rem;
$base-quarter-spacing: 0.5rem;
$base-header-height: 2.875rem;
$base-header-height_sm: 2.875rem;
$base-nav-main-height_sm: 2.875rem;
$base-nav-main-dropdown-width_sm: 12rem;
$base-nav-main-dropdown-width-wider_sm: 24rem;
$base-border-width: 1px;

/* APC */

$apc-spacing: 1rem;
$apc-spacing_sm: 2rem;
$apc-spacing_md: 3rem;
$apc-spacing_lg: 4rem;

/* ITEMS IN A ROW */

$items-1: 100%;
$items-2: 50%;
$items-3: 33.3333333%;
$items-4: 25%;

/* BANNERS */

$takeover-banner: calc(100vh - 90px);
$takeover-banner-ios: calc(100vh - 158px);
$takeover-banner_sm: calc(100vh - 160px);
$takeover-banner-ios_sm: calc(100vh - 186px);
$takeover-banner-ios-no-header: calc(100vh - 68px);
$takeover-banner-ios-no-header_sm: calc(100vh - 26px);

///////////////////////
// Additional vars

$body-font: 'Muli', Helvetica, Arial, sans-serif;