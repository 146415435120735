﻿/// <reference path="../../../main">

$_video-ui-offset: 15rem;

.hero {
    position: relative;

    @include media(medium) {
        min-height: calc(75vh - #{$mobile-nav-height});
        margin-top: 0;
    }

    &-background, &-video-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    &-background {
        display: flex;
        justify-content: center;
    }

    &-image {
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
    }

    &-video {
        width: 100%;
        overflow: hidden;
        position: relative;
        min-height: calc(75vh - #{$base-nav-main-height_sm});
        display: flex;
        height: 100%;

        @include media(medium) {
            min-height: calc(75vh - #{$mobile-nav-height});
        }

        iframe {
            width: 100%;
            height: calc(100% + #{$_video-ui-offset * 2});
            margin-top: -$_video-ui-offset;
            flex: 0 0 auto;
        }

        video {
            height: 100%;
            min-width: 100%;
            width: #{100 * (16/9)}vh;
            min-height: #{100 * (9/16)}vw;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &-container {
            overflow: hidden;
        }

        &-controls {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: calc(75vh - #{$base-nav-main-height_sm});

        &.text-left {
            justify-content: flex-start;
        }

        &.text-right {
            justify-content: flex-end;
        }

        &-inner {
            font-size: var(--responsive-regular);
            max-width: $max-line-length;
            position: relative;
            pointer-events: none;

            a, button {
                pointer-events: all;
            }
        }
    }

    @include responsive-context($standard-padding-map, $heading-spacing-map) {
        &-content {
            padding: $responsive-variable-2 $responsive-variable;
        }

        &-video-controls {
            right: $responsive-variable;
            bottom: $responsive-variable-2;
        }
    }
}

// Deal with showing/hiding on load
.hero {
    &-video-container,
    &-image {
        transition: $transition;
        opacity: 0;
        pointer-events: none;
    }

    &-video-container {
        &:not([aria-hidden="true"]) {
            opacity: 1;
        }
    }

    &-image {
        &:not([aria-hidden="true"]).loaded {
            opacity: 1;
        }
    }
}