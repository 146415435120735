﻿// <reference path="../main.scss" / >

@mixin visually-hidden {
    margin: -1px !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0 0 0 0) !important;
    clip: rect(0, 0, 0, 0) !important;
    position: absolute !important;
}

%visually-hidden,
.visually-hidden,
.sr-only {
    @include visually-hidden;
}

@mixin hide {
    display: none !important;
    visibility: hidden !important;
}

@mixin unhide {
    display: block !important;
    visibility: visible !important;
}

%hide,
.hide {
    @include hide;
}