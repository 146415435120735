/// <reference path="../../../main">

$_forms-max-input-width: $max-input-width;
$_forms-input-padding: $space;
$_forms-font: $body-font;

/// Input types that are visually compatible with text style inputs
$input-types: ( "text", "number", "password", "tel", "email", "search", "url", "date", "month", "week", "time", "datetime", "datetime-local" );

%form-element {
    /* minimum size for form element font-size to prevent iOS resizing is 16px */
    font-size: 1rem;
    padding: $_forms-input-padding;
    width: 100%;
    max-width: $_forms-max-input-width;
    font-family: $_forms-font;
    border: 1px solid $c4;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.75;
    }

    &::placeholder {
        color: rgba($disabled-gray, 0.7);
        font-style: italic;
    }
}

@mixin all-form-elements { // only use this with @extend contents or you're gonna generate a ton of css
    input {
        @each $type in $input-types {
            &[type=#{$type}] {
                @content;
            }
        }
    }

    select,
    textarea,
    .form-control {
        @content;
    }
}

@include all-form-elements {
    @extend %form-element;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

label:nth-child(n) { // need to override bootstrap's nested normal font weight
    @extend %small;
    font-weight: $heading-font-weight-bold;
}

label:not(.hidden):not(.visually-hidden) {
    & + input,
    & + textarea,
    & + select {
        margin-top: $sliver;
    }
}

.form-control {
    height: auto;
    min-height: 0;

    @include focus();
}

fieldset {
    @include responsive-context($vertical-padding-map) {
        margin-top: $responsive-variable;
    }
}

legend {
    @extend %h4;

    & + * {
        @extend %top-margin;
    }
}