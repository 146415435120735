﻿/// <reference path="../../../main">

.text-image {
    &-inner {
        @include media-min(small) {
            display: flex;
            justify-content: space-between;
        }

        @include media-range(small, large) {
            & > * {
                width: calc(50% - #{$gap / 2});
                flex: 0 0 auto;
            }
        }
    }

    &-reversed {
        flex-direction: row-reverse;
    }

    &-panel {
        &-txt {
            display: flex;
            align-items: flex-start;

            @include media-min(large) {
                align-items: center;
                width: 50%;
                margin-left: $gap * 2;
            }

            @include media(small) {
                margin-top: $gap-xs;
            }
        }

        &-img {
            display: flex;
            align-items: center;

            @include media-min(large) {
                width: calc(50% - #{$gap * 4});
            }
        }
    }

    &-border {
        padding-left: $gap-xs;
        padding-bottom: $gap-xs;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: calc(100% - #{$gap-xs});
            height: calc(100% - #{$gap-xs});
            left: 0;
            bottom: 0;
            display: block;
        }

        @each $i, $c in $usn-colors {
            &.c#{$i}-bg-border {
                &::before {
                    background: $c;
                }
            }
        }
    }

    &-image {
        border-color: $c5;
        border-style: solid;
        border-width: 0 0 $ui-icon-thickness $ui-icon-thickness;
        display: block;
        position: relative;
    }
    // GRID
    @supports(display:grid) {
        &-panel {
            &-img {
                width: auto;

                @include media-min(large) {
                    &.text-image-right {
                        grid-column: 8 / span 5;
                    }

                    &.text-image-left {
                        grid-column: 1 / span 5;
                    }
                }
            }

            &-txt {
                width: auto;
                margin-left: 0;

                @include media-min(large) {
                    &.text-image-right {
                        grid-column: 7 / span 5;
                    }

                    &.text-image-left {
                        grid-column: 2 / span 5;
                    }
                }
            }

            @include media(large) {
                &-img, &-txt {
                    &.text-image-left {
                        grid-column: 1 / span 6;
                    }

                    &.text-image-right {
                        grid-column: 7 / span 6;
                    }
                }
            }
        }

        &-inner {
            @include media-min(small) {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                grid-gap: $gap;
                grid-auto-flow: dense;
            }
        }
    }
}
