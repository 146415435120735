/// <reference path="../../../main">
$_font-size-default-xx-large: 3.5rem;
$_font-size-default-x-large: 2.25rem;
$_font-size-default-large: 1.75rem;
$_font-size-default-med-large: 1.5rem;
$_font-size-default-medium: 1.25rem;
$_font-size-default-regular: 1.125rem;
$_font-size-default-small: 1rem;
$_font-size-default-x-small: 0.875rem;
$_font-size-default-tiny: 0.75rem;

$_typography-paragraph-spacing: 0.875em;
$_normal-line-height: 1.5;

:root {
    --responsive-h1: #{$_font-size-default-xx-large};
    --responsive-h2: #{$_font-size-default-x-large};
    --responsive-h3: #{$_font-size-default-large};
    --responsive-h4: #{$_font-size-default-med-large};
    --responsive-h5: #{$_font-size-default-medium};
    --responsive-h6: #{$_font-size-default-small};
    --responsive-regular: #{$_font-size-default-regular};
    --responsive-caption: #{$_font-size-default-small};
    --navigation-font-size: #{$_font-size-default-regular};
    --responsive-tiny: #{$_font-size-default-tiny};

    @include media-range(medium, large) {
        --navigation-font-size: 1rem;
    }

    @include media(medium) {
        --navigation-font-size: 1.125rem;
        --responsive-h1: 3rem;
        --responsive-h2: 2.125rem;
        --responsive-h3: 1.625rem;
        --responsive-h4: 1.325rem;
        --responsive-h5: 1.125rem;
        --responsive-regular: 1rem;
        --responsive-caption: 0.875rem;
        --responsive-tiny: 0.625rem;
    }

    @include media(small) {
        --responsive-h1: 2.25rem;
        --responsive-h2: 1.75rem;
        --responsive-h3: 1.5rem;
        --responsive-h4: 1.125rem;
        --responsive-h5: 1rem;
    }

    --text-margin: #{$_typography-paragraph-spacing};
}

body {
    font-family: $body-font;
    font-variant-numeric: lining-nums;
    -moz-font-feature-settings: "lnum";
    -webkit-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
    font-size: var(--responsive-regular);
}

// FONT SIZES
%h1 {
    font-size: $_font-size-default-xx-large;
    font-size: var(--responsive-h1);
    line-height: 1.175;
}

%h2 {
    font-size: $_font-size-default-x-large;
    font-size: var(--responsive-h2);
    line-height: 1.25;
}

%h3 {
    font-size: $_font-size-default-large;
    font-size: var(--responsive-h3);
    line-height: 1.335;
}

%h4 {
    font-size: $_font-size-default-med-large;
    font-size: var(--responsive-h4);
    line-height: 1.43;
}

%h5,
.large,
.intro {
    font-size: $_font-size-default-medium;
    font-size: var(--responsive-h5);
    line-height: 1.485;
}

%body,
p,
label,
input,
blockquote,
li {
    font-size: $_font-size-default-small;
    font-size: var(--responsive-regular);
    line-height: $_normal-line-height;
}

%small,
%h6,
.small,
.small p,
.small li {
    font-size: $_font-size-default-small;
    font-size: var(--responsive-h6);
    line-height: $_normal-line-height;
}

%caption,
.caption,
.caption p,
.caption li {
    @extend %small;
    font-size: $_font-size-default-x-small;
    font-size: var(--responsive-caption);
    line-height: 1.57;
}

.caption {
    color: $c2;
}

%tiny,
.tiny {
    font-size: $_font-size-default-tiny;
    font-size: var(--responsive-tiny);
    line-height: $_normal-line-height;
}

// FONT WEIGHTS, CAPS
%h1,
%h2,
%h3,
%h4,
%h5,
%h6 {
    font-weight: $body-font-weight-bold;
    font-family: $body-font;
}

%h6 {
    text-transform: uppercase;
    letter-spacing: 0.09em;
}

%top-margin {
    margin-top: $regular-text-margin;
    margin-top: var(--text-margin);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
address {
    @at-root {
        * + & {
            @extend %top-margin;
        }
    }
}

.heading + .text {
    @extend %top-margin;
}

.text + * {
    @extend %top-margin;
}

.secondary-heading + * {
    margin-top: $small-text-margin;
}

.visually-hidden + * {
    margin-top: 0;
}

.light {
    font-weight: $body-font-weight-normal;
}

.bold {
    font-weight: $heading-font-weight-bold;
}

sup,
sub {
    font-size: calc(50% + 0.4rem);
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.4em;
}

sub {
    top: 0.4em;
}

$_primary-link: $c1;
$_primary-link-hover: $base-text;

a:not([class*="btn"]),
.hyperlink {
    &:hover { // overriding some bootstrap stuff
        @extend %pre-focus-styles;
    }
}

a,
.hyperlink {
    text-decoration: underline;
    @include link-helper($c1, $base-text);
}

.subtle-link {
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.dark,
%dark {
    color: $true-white;

    a {
        @include link-helper($true-white, $c4);
    }
}

.link-img {
    display: inline-block;
}

h1, .h1-style {
    &, & > p {
        @extend %h1;
    }
}

h2, .h2-style {
    &, & > p {

        @extend %h2;
    }
}

h3, .h3-style {
    &, & > p {
        @extend %h3;
    }
}

h4, .h4-style {
    &, & > p {
        @extend %h4;
    }
}

h5, .h5-style {
    &, & > p {
        @extend %h5;
    }
}

h6, .h6-style {
    &, & > p {
        @extend %h6;
    }
}

ul, ol {
    &.unstyled {
        list-style-type: none;
        margin-left: 0;
    }
}

.titling {
    text-transform: uppercase;
    letter-spacing: 0.5em;
}

.text-3-col,
.text-2-col {
    column-count: 2;

    &:nth-child(n) {
        max-width: none;
    }

    @include media(mobile) {
        column-count: 1;
    }

    @include responsive-context($gutter-map) {
        column-gap: $responsive-variable;
    }
}

@include media-min(small) {
    .text-3-col {
        column-count: 3;
    }
}

.quote-text {
    font-size: $_font-size-default-x-large;
    font-size: var(--responsive-h2);
    font-style: italic;
}

blockquote {
    &::before {
        content: ' \275D';
        @extend %h1;
        line-height: 1;
        font-weight: $heading-font-weight-light;
    }
}