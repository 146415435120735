/// <reference path="../../../main">

/*  
--------------------------------------------------
5.0 NAVIGATION             
-------------------------------------------------- 
*/

html.reveal-out {
    overflow: hidden;
}

.navigation {
    position: relative;
    z-index: 5;
}

html.reveal-out .navigation {
    overflow: auto;
}

.navigation .reveal {
    display: none;
}

html.reveal-out .navigation .reveal {
    display: block;
}

header a.expand {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 300;
    width: 60px;
    height: 60px;
    display: block;
    text-indent: -9999px;
    text-decoration: none;
    cursor: pointer;
    background-color: $true-white;
}

header a.expand span:before,
header a.expand span,
header a.expand span:after {
    content: "";
    display: block;
    width: $base-spacing;
    height: 3px;
    position: absolute;
    left: 0;
    background-color: $base-text;
    opacity: 1;
    transition: transform 0.4s ease-in-out 0s, left 0.4s ease-in-out 0s, top 0.4s ease-in-out 0s, width 0.3s ease-in-out 0s;
}

header a.expand span {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

header a.expand span:before {
    top: -8px;
}

header a.expand span:after {
    top: 8px;
}

header a.expand.active span {
    width: 0;
}

header a.expand.active span:before,
header a.expand.active span:after {
    top: 0;
    left: -15px;
}

header a.expand.active span::before {
    transform: rotate(-45deg);
}

header a.expand.active span::after {
    transform: rotate(45deg);
}

@media (min-width:0) and (max-width:767px) {
    html.reveal-out #site {
        height: 0px;
    }
}

@media (min-width:768px) {
    html.reveal-out {
        overflow: auto;
    }
    html.reveal-out .navigation {
        overflow: visible;
    }
    header a.expand {
        display: none;
    }
    .navigation .reveal {
        display: block;
    }
}


/*  
--------------------------------------------------
5.1 MAIN NAV             
-------------------------------------------------- 
*/

.navigation nav.main ul li span i {
    display: inline-block;
    text-align: center;
    color: $base-text;
}

.navigation nav.main ul li span i {
    z-index: 99;
}

html.touch .navigation nav.main ul li span i {
    z-index: 101;
}

.navigation nav.main ul li a {
    display: block;
}

.navigation nav.main>ul>li>span a {
    position: relative;
    color: $base-text;
}

.navigation nav.main ul li span a {
    z-index: 101;
}

html.touch .navigation nav.main>ul>li>span a {
    z-index: 99;
}

.navigation nav.main ul li span.active a {
    font-weight: bold;
}

.navigation nav.main ul li ul li span>a {
    display: block;
}

.navigation nav.main>ul>li.has-child ul {
    position: relative;
}

.navigation nav.main ul li span a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 100%;
}

@media (min-width:0) and (max-width:767px) {
    .navigation nav.main ul li.has-child.open-mobile>ul {
        display: block;
    }
    .navigation nav.main>ul>li>span a {
        z-index: 99;
    }
    .navigation nav.main ul li span i {
        z-index: 101;
    }
    .navigation nav.main ul li.has-child.open>span i::before {
        transform: rotate(180deg);
    }
    .navigation nav.main ul li.more-children {
        display: none!important;
    }
}

@media (min-width:768px) {
    .navigation,
    .navigation nav.main,
    .navigation nav.main ul {
        z-index: 500;
        position: relative;
    }
    .navigation nav.main {
        position: absolute;
        top: 100px;
        right: 15px;
        left: 15px;
        height: $base-nav-main-height_sm;
    }
    .navigation nav.main>ul {
        position: relative;
        z-index: 1000;
        line-height: 0;
        border: 0 none;
    }
    .navigation nav.main>ul>li>span {
        position: relative;
    }
    .navigation nav.main>ul>li {
        z-index: 1000;
    }
    /* DROPDOWN NAVIGATION */
    .navigation nav.main>ul>li.has-child {
        position: relative;
    }
    .navigation nav.main>ul>li.has-child ul {
        position: absolute;
        top: $base-nav-main-height_sm;
        z-index: 2000;
        width: $base-nav-main-dropdown-width_sm;
        height: auto;
        padding: 0;
        text-align: left;
        box-shadow: 5px 5px 0 rgba($c3, 0.05);
    }
    .navigation nav.main>ul>li.has-child ul li {
        position: relative;
    }
    .navigation nav.main ul li.has-child ul li span a {
        display: block;
        float: none;
        height: auto;
    }
    .navigation nav.main ul li.has-child ul li span i {
        top: 0;
        bottom: 0;
        font-size: 10px;
        height: 100%;
    }
    .navigation nav.main ul li.more-children ul.more-dropdown ul,
    .navigation nav.main ul li.more-children ul.more-dropdown i {
        display: none!important;
    }
    /* MULTI COLUMN DROPDOWN FOR MORE THAN 8*/
    .navigation nav.main>ul>li.has-child.multi-col>ul {
        width: $base-nav-main-dropdown-width-wider_sm;
    }
    .navigation nav.main>ul>li.has-child.multi-col>ul>li {
        float: left;
        width: $base-nav-main-dropdown-width_sm;
    }
    .navigation nav.main>ul>li.has-child.multi-col>ul>li:nth-child(2n+1) {
        clear: both;
    }
}


/*  
--------------------------------------------------
5.3 SUB NAVIGATION             
-------------------------------------------------- 
*/

nav.sub .heading {
    border-width: 0 0 $base-border-width;
    border-style: solid;
}


/*  
--------------------------------------------------
5.4 BREADCRUMB NAVIGATION             
-------------------------------------------------- 
*/

.breadcrumb-trail {
    overflow: hidden;
    height: 60px;
    border-width: $base-border-width 0 0;
    border-style: solid;
    border-color: rgba($c4, 1);
}

.breadcrumb-trail ol {
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: 14px;
}

.breadcrumb-trail ol li {
    position: relative;
    display: inline-block;
}

.breadcrumb-trail ol li.active {
    color: lighten($base-text, 25%);
}

.breadcrumb-trail ol li a {
    display: inline-block;
    height: 60px;
}

.breadcrumb-trail ol li span {
    padding: 23px 40px 0 10px;
    display: block;
    height: 60px;
}

.breadcrumb-trail ol li:first-child span {
    padding-left: 0;
}

.breadcrumb-trail ol li::before,
.breadcrumb-trail ol li::after {
    content: "";
    position: absolute;
    top: -7px;
    right: 15px;
    width: $base-border-width;
    height: 40px;
    transform: rotate(-30deg);
}

.breadcrumb-trail ol li::after {
    top: auto;
    bottom: -7px;
    transform: rotate(30deg);
}

.breadcrumb-trail ol li.active::before,
.breadcrumb-trail ol li.active::after {
    display: none;
}

@media (min-width:768px) {}

@media (min-width:992px) {}

@media (min-width:1200px) {}


/*  
--------------------------------------------------
5.5 PAGINATION NAVIGATION             
-------------------------------------------------- 
*/

nav#pagination {
    padding: $base-spacing 0;
}

nav#pagination.post {}

nav#pagination .pagination {
    position: relative;
    overflow: hidden;
    min-height: 50px;
    margin: 0;
    display: block;
    font-size: 0;
}

.listing.shop nav#pagination {
    margin: 0;
}

nav#pagination .pagination,
nav#pagination .pagination li a,
nav#pagination .pagination li a span {
    border-radius: 0;
}

.listing.shop nav#pagination .pagination {
    border: 0 none;
}

nav#pagination .pagination li {
    border: 0 none;
    display: inline-block;
    font-size: 14px;
    margin: 0 2px;
}

nav#pagination .pagination li.prev,
nav#pagination .pagination li.next {
    position: absolute;
    top: 0;
    left: 0;
}

nav#pagination .pagination li.next {
    right: 0;
    left: auto;
}

nav#pagination .pagination li a {
    border-width: $base-border-width;
    border-style: solid;
    border-color: rgba($c6, 1);
    padding: 13px 20px;
    color: rgba($c1, 1);
}

html.no-touch nav#pagination .pagination li:not(.active) a:hover {
    color: rgba($c5, 1);
    border-color: rgba($c1, 1);
}

nav#pagination .pagination li.prev,
nav#pagination .pagination li.next {
    margin: 0;
}

nav#pagination .pagination li.active a {
    font-weight: bold;
    color: rgba($c5, 1);
    border-color: rgba($c3, 1);
}


/*  
--------------------------------------------------
5.6 SITEMAP NAVIGATION             
-------------------------------------------------- 
*/

nav.sitemap {
    overflow: hidden;
    height: 100%;
    position: relative;
}

nav.sitemap ul {
    margin: 0;
    padding: 0 0 8px;
}

nav.sitemap ul li {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
}

.c1-bg nav.sitemap ul li a {
    color: rgba($true-white, 1);
}

nav.sitemap ul li::before,
nav.sitemap ul li::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    background-color: rgba($base-borders, 1);
    transition: all 0.3s ease-in-out 0s;
}

.c1-bg nav.sitemap ul li::before,
.c1-bg nav.sitemap ul li::after {
    background-color: lighten($c1, 10%);
}

.c3-bg nav.sitemap ul li::before,
.c3-bg nav.sitemap ul li::after {
    background-color: lighten($c3, 20%);
}

nav.sitemap ul li::before {
    top: 0;
    bottom: 0;
    width: $base-border-width;
    height: 100%;
}

nav.sitemap ul li::after {
    top: 16px;
    width: 5px;
    height: $base-border-width;
}

html.no-touch nav.sitemap ul li a:hover {
    padding-left: 18px;
}

html.no-touch nav.sitemap ul li:hover::after {
    width: 10px;
    background-color: darken($base-borders, 5%);
}

html.no-touch .c1-bg nav.sitemap ul li:hover::after {
    background-color: lighten($c1, 20%);
}

nav.sitemap ul ul {
    margin-left: 16px;
}

/*  
--------------------------------------------------
5.8 QUICK LINKS           
-------------------------------------------------- 
*/

.quick-links {}

.quick-links ul {}

.quick-links ul li {
    margin-bottom: 10px;
}

.quick-links ul li a {
    display: block;
    padding-left: 10px;
}

.quick-links ul li a::after {
    content: "";
    position: absolute;
    top: 7px;
    left: $base-half-spacing;
    display: block;
    width: 5px;
    height: $base-border-width;
    background-color: rgba($c3, 0.3);
}


/*  
--------------------------------------------------
5.9 FOOTER NAVIGATION             
-------------------------------------------------- 
*/

nav.footer {
    padding: 80px 0 0;
}

nav.footer ul {
    font-size: 12px;
}

nav.footer ul li {
    display: block;
    padding: 10px 0;
}

nav.footer ul li a,
nav.footer ul li div {
    display: inline;
    padding: 0;
}

@media (min-width:768px) {
    nav.footer ul li {
        margin: 0 8px 0 0;
        padding: 0;
        display: inline-block;
    }
    nav.footer ul li.site-credit {
        clear: both;
        display: block;
        padding: 40px 0 0;
    }
}

@media (min-width:992px) {
    nav.footer ul li.site-credit {
        float: right;
        clear: none;
        padding: 0;
    }
}


/*  
--------------------------------------------------
5.10 SLICK CAROUSEL NAVIGATION            
-------------------------------------------------- 
*/

.slick-prev,
.slick-next {
    display: table;
}

.slick-prev i,
.slick-next i {
    position: relative;
    margin: 0!important;
    display: block;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.slick-prev,
.slick-next {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    z-index: 200;
    background: none;
    border: 0 none;
    cursor: pointer;
    color: rgba($c5, 1);
    background-color: rgba($c1, 0.8);
    transform: translateY(-50%);
}

.slick-prev {
    left: $base-half-spacing;
    background-color: rgba($c1, 0.4);
}

.slick-next {
    right: $base-half-spacing;
}

.apc.banner .slick-prev,
.apc.text-with-slideshow .slick-prev {
    left: 0;
}

.apc.banner .slick-next,
.apc.text-with-slideshow .slick-next {
    right: 0;
}

html.no-touch .slick-prev:hover,
html.no-touch .slick-next:hover {
    background-color: rgba($c1, 1);
}

.slick-prev i,
.slick-next i {
    font-size: 15px;
}

.apc.banner .slick-prev i,
.apc.banner .slick-next i {
    font-size: 20px;
}

ul.slick-dots {
    position: relative;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding-top: $base-half-spacing;
    text-align: center;
}

.apc.banner ul.slick-dots,
.apc.text-with-slideshow ul.slick-dots {
    position: absolute;
    right: 0;
    bottom: 20px;
    left: 0;
    padding: 0;
}

ul.slick-dots li {
    margin: 0 5px;
    display: inline-block;
    width: 12px;
    height: 12px;
}

.text-left ul.slick-dots {
    left: $base-half-spacing;
}

.text-left ul.slick-dots li {
    float: left;
    margin-right: 10px;
    margin-left: 0;
}

.text-right ul.slick-dots {
    right: $base-half-spacing;
}

.text-right ul.slick-dots li {
    float: right;
    margin-right: 0;
    margin-left: 10px;
}

ul.slick-dots li button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: rgba($c3, 0.4);
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
}

ul.slick-dots li.slick-active button {
    background-color: rgba($c3, 1);
}

.apc.banner ul.slick-dots li button,
.apc.text-with-slideshow ul.slick-dots li button,
.c1-bg ul.slick-dots li button,
.c2-bg ul.slick-dots li button,
.c3-bg ul.slick-dots li button {
    background-color: rgba($c5, 0.4);
}

.apc.banner ul.slick-dots li.slick-active button,
.apc.text-with-slideshow ul.slick-dots li.slick-active button,
.c1-bg ul.slick-dots li.slick-active button,
.c2-bg ul.slick-dots li.slick-active button,
.c3-bg ul.slick-dots li.slick-active button {
    background-color: rgba($c5, 1);
}

@media (min-width:0) and (max-width:767px) {
    .apc.banner .slick-prev,
    .apc.banner .slick-next {
        top: auto;
        bottom: 0;
        transform: translateY(0)
    }
}

@media (min-width:768px) {}

@media (min-width:992px) {
    .apc.banner .slick-prev,
    .apc.banner .slick-next {
        width: 50px;
        height: 50px;
    }
}

@media (min-width:1200px) {}