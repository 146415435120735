﻿/// <reference path="../../../main">

.gallery {
    &-block {
        // add padding if the background is not white (that will have margins)
        &:not(.c5-bg) {
            @include responsive-context($vertical-padding-map) {
                padding-top: $responsive-variable;
                padding-bottom: $responsive-variable;
            }
        }
    }

    &-container {
        @extend %max-total-width;

        & > .row {
            margin-left: auto;
            margin-right: auto;

            @include column-width(8);

            @include media(large) {
                @include column-width(12);
            }
        }
    }
}

.slick {
    &-track {
        display: flex;
    }

    &-slide {
        float: none;
        height: auto;
    }
}

.item.no-crop {
    display: flex;

    & > * {
        display: flex;
    }

    .link-img {
        display: flex;
    }

    img {
        align-self: center;
    }
}
