﻿/// <reference path="../../../main">

// Flexbox equivalent to bootstrap setup

@for $i from 0 through 12 {
    .cols-#{$i} {
        @include column-width($i);

        @include media(medium) {
            width: auto;
        }
    }

    .grid-row {
        .cols-#{$i} {
            @include column-width($i, true);

            @include media(medium) {
                @if $i < 4 or $i == 6 {
                    @include column-width($i * 2, true);
                }
                @if $i == 4 {
                    @include column-width($i + 2, true);
                }
            }

            @include media(small) {
                width: auto;
            }
        }
    }
}

.grid-row {
    display: flex;
    flex-wrap: wrap;

    .column {
        flex: 0 1 auto;
    }

    @include responsive-context($gutter-map) {
        margin-right: -$responsive-variable;
        margin-bottom: -$responsive-variable;

        & > * {
            margin-right: $responsive-variable;
        }

        .column {
            margin-bottom: $responsive-variable;
        }
    }
}