﻿/// <reference path="../../../main">

.cta {
    &-inner {
        @include responsive-context($vertical-padding-map) {
            padding-top: $responsive-variable * 1.5;
            padding-bottom: $responsive-variable * 1.5;
        }

        position: relative;
    }

    &-content {
        max-width: $max-line-length;

        @at-root {
            .text-center & {
                margin-left: auto;
                margin-right: auto;
            }

            .text-right & {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    &-pattern {
        @extend %background-pattern;
    }
}