﻿/// <reference path="../../../../main">

.pdp-overview {
    &-inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: flex-start;

        @include media(large) {
            display: block;
        }

        & > * {
            flex: 0 0 auto;

            @include responsive-context($gutter-map) {
                &:last-child {
                    width: calc(#{$one-third * 2} - #{$responsive-variable / 3});
                }

                &:first-child {
                    width: calc(#{$one-third} - #{$responsive-variable / 3 * 2});
                }
            }

            @include media(large) {
                &:nth-child(n) {
                    width: auto;
                }
            }
        }
    }

    &-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        @include responsive-context($heading-spacing-map) {
            margin-top: $responsive-variable;
        }

        @include media-min(large) {
            margin-top: 0;
        }

        &-main {
            flex: 1 1 auto;
            width: 100%;
            --text-margin: 1em;
        }

        &-item {
            flex: 0 0 auto;

            @include responsive-context($gutter-map) {
                margin-top: $responsive-variable;
                width: calc(50% - #{$responsive-variable / 2});
            }

            @include media(mobile) {
                width: 100%;
            }

            &:last-child:nth-child(2) {
                flex: 1 1 auto;
            }
        }
    }

    &-performance {
        @include responsive-context($gutter-map) {
            margin-top: $responsive-variable;
        }

        &-item {
            font-size: $_font-size-default-large;
            font-size: var(--responsive-h3);

            & > *:not(h3) {
                @extend %small;
            }
        }

        & > * {
            margin-bottom: $regular-text-margin;
            margin-bottom: var(--text-margin);

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include media-range(mobile, large) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            & > * {
                width: calc(50% - #{$max-column-gap / 2});

                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-date {
        &-list {
            &-item {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                border-bottom: 1px solid $c4;
                padding: $gap-sm 0;
                margin: 0;

                &:first-child {
                    padding-top: 0;
                }

                & > *:first-child {
                    margin-right: $gap-xs;
                }
            }
        }
    }
}
