﻿/// <reference path="../../../main">

@include media(medium) {
    header {
        height: $mobile-nav-height;
    }

    nav.top {
        position: static;
    }

    nav.main {
        display: block;
    }

    .main-nav {
        &-ul0 {
            display: block;
        }

        &-li0 {
            padding: 0;
            border-top: 1px solid $c4;
            display: block;

            &:first-child {
                border: 0;
            }

            &.has-child::after {
                content: none;
            }

            &.has-child:hover {
                color: $base-text;
            }

            &-text, &-button {
                padding: $gap-xs 0;
                display: flex;
                justify-content: space-between;
                text-align: left;
                cursor: pointer;
            }

            &-button:focus {
                color: $c1;
            }

            &:not(.open):not(.open-mobile) .main-nav-ul1 {
                height: 0 !important;
            }

            &.open, &.open-mobile {
                i::before {
                    transform: rotate(-180deg);
                }
            }

            i {
                color: $c1;
            }
        }

        &-ul1 {
            transition: 0.5s height;

            &:focus-within:nth-child(n):nth-child(n) {
                height: auto !important;
            }
        }

        &-li1 {
            margin-bottom: $gap-sm;

            &-link {
                padding: $sliver 0;
            }
        }
    }
}

header {
    .expand {
        position: relative;
        width: $svg-icon-size;
        height: $svg-icon-size;
        background-color: transparent;
        border: 0;
        text-indent: -9999em;
        cursor: pointer;

        span {
            &, &::before, &::after {
                background-color: $true-white;
                width: $svg-icon-size;
                height: $ui-icon-thickness;
                transition: $transition all;
                content: "";
                display: block;
                position: absolute;
                background-color: white;
                left: 50%;
                transform-origin: center center;
                transform: translateX(-50%);
            }

            &::before {
                top: -$space;
            }

            &::after {
                top: $space;
            }
        }

        &:hover,
        &:focus {
            span {
                &, &:before, &:after {
                    background-color: $c4;
                }
            }
        }

        &.active {
            span {
                background: transparent;

                &::before, &::after {
                    top: 50%;
                }

                &::before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &::after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }
}

.nav-mobile {
    &-links {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $mobile-nav-height;

        @include media-min(medium) {
            display: none;
        }

        & > * {
            @include link-helper($true-white, $c4);
            margin-right: $gap-xs;
        }
    }
}
