﻿/// <reference path="../../../main">

#logo {
    width: $base-header-height * 2;
    height: $base-header-height * 2;
    transition: $transition ease-in-out;
    bottom: -$base-header-height * 2;
    top: auto;
    left: 0;
    right: auto;

    a {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        transition: $transition linear;
        transform-origin: left bottom;
    }
}

.nav-up #logo a {
    @include media-min(medium) {
        transform: scale(.5);
    }
}

@include media(medium) {
    #logo {
        width: $mobile-nav-height;
        height: $mobile-nav-height;
        bottom: auto;
        top: 0;
        transform: none;
    }
}